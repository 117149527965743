
import React from "react";
import '../../assets/css/App.css';
import '../../assets/css/style.css';
import { useEffect } from 'react';
import logo from '../../assets/Images/kr.jpeg';

const Sidebar = () => {
    var data = localStorage.getItem('userData');
    var result = JSON.parse(data);

    useEffect(() => {
        if (result === null || data === null) {
            window.location.href = "/";
        }
    });

    const currentPathName = window.location.pathname
    
    return (
        <div id="sidebar">
            <div className="sidebar-header text-center">
                <img src={logo} className="App-logo" alt="logo" style={{ width: 70  , borderRadius: 18, padding: 10}}/> ATES WR
            </div>
            
            <ul className="list-unstyled components pt-1">                
                <li className={currentPathName === '/dashboard' ? "active" : ""} > 
                    <a href="/dashboard"> <i className="fa fa-home" ></i> <span className="ml-2" >Home</span></a>
                </li>
                <li>
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa fa-cog" ></i> <span className="ml-2" >Master</span></a>
                    <ul className={currentPathName === '/site' || currentPathName === '/conatct' ? "collapse list-unstyled show" : "collapse list-unstyled"} id="homeSubmenu">
                        <li className={currentPathName === '/site' ? "active" : ""} >
                            <a href="/site" style={{ paddingLeft: 30 }} >Site</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
};

export default Sidebar;
