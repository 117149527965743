import React from 'react';
import Sidebar from "../nav_src/Sidebar";
import TopNavBar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './global';
import Footer from '../nav_src/Footer';

//jQuery libraries

import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import {Link} from 'react-router-dom';

//For API Requests
import axios from 'axios';

class Site extends React.Component {
  
  // State array variable to save and show data
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }
  
  componentDidMount() {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    //Get all users details in bootstrap table
    axios.post(global.passUrl+'get_sites', axiosConfig)
    .then((res) => {
      this.setState({ data: res.data });
      console.log(res.data);
    });

    //initialize datatable
    $(document).ready(function () {
      setTimeout(function () {
        $('#example').DataTable();
      }, 1000);
    });
  }

  componentDidUpdate() {
    $('#example').DataTable().destroy();
  }

  handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    var postData = {
      site_name: data.get('site_name'),
      unique_id: data.get('unique_id'),
    };
    
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };    

    if (postData.site_name) {
      axios.post(global.passUrl+'add_site', postData, axiosConfig)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message,{ theme: "colored", })
            setInterval(() => {
              window.location.href = '';
            }, 5000);
          } else {
            toast.dismiss()
            toast.error(res.data.message,{ theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill!!",{ theme: "colored", })
          setInterval(() => {
            window.location.href = '';
          }, 5000);
        }
        
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
    } else {
      toast.dismiss()
      toast.error("Site Name is Required!",{ theme: "colored", })
    }
  }

  deleteRow(id,e) {
    e.preventDefault();    
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this site!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        var postData = {
          s_id: id,
        };
        
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
          }
        };    
    
        axios.post(global.passUrl+'delete_site', postData, axiosConfig)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            toast.dismiss()
            toast.success("Site deleted successfully...",{ theme: "colored", })
            
            setInterval(() => {
              window.location.href = '';
            }, 5000);
          } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Fail!!",{ theme: "colored", })
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
      } else {
        
      }
    });

  }

  getUniqueId(e){
    var postData = {
      unique_id: e.target.value,
    };
    
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };    

    axios.post(global.passUrl+'getUniqueSiteId', postData, axiosConfig).then((res) => {
      console.log(res);
      if (res.status === 200) {
        if (res.data.status === 'error') {
          toast.dismiss()
          toast.error(res.data.message,{ theme: "colored", })
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Faill!!",{ theme: "colored", })
      }
    }).catch((err) => {
      toast.dismiss()
      toast.error(err,{ theme: "colored", })
    })
  }

  render() {
    return (
      <div className="wrapper">
        <ToastContainer />
        <Sidebar />
        <div className='page_layout'>
          <div><TopNavBar /></div>
          <div id="content">
            <div className="container-fluid">
              <div className="block-header">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Site</h2>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                      <li className="breadcrumb-item active">Site</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-right">
                    <span className="label btn-info rounded ml-2 cursor-pointer" data-toggle="modal" data-target="#exampleModal" ><i className="fa fa-plus"></i> Add Site</span>
                  </div>
                </div>
                <div className="row mt-4">
                  <table id="example" className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Site Name</th>
                        <th>Site Unique Id</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data.map((result,index) => {
                        return (
                          <tr key={index} >
                            <td>{index + 1}</td>
                            <td>{result.site_name}</td>
                            <td>{result.unique_id}</td>
                            <td className='text-center' >
                              <div className='d-flex'>
                                <Link to={`/edit_site/${result.s_id}`}>
                                  <span className="label edit_btn rounded cursor-pointer" title='Edit Site'
                                  ><i className="fa fa-edit"></i></span>
                                </Link>
                                <Link>
                                  <span className="label delete_btn rounded ml-2 cursor-pointer" title='Delete Site' onClick={(e) => this.deleteRow(result.s_id, e)}><i className="fa fa-trash"></i></span>
                                </Link>

                                <Link to={`/contacts/${result.s_id}`}>
                                  <span className="label btn-dark rounded ml-2 cursor-pointer"  data-toggle="tooltip" data-placement="top" title="Craete Update Conatct.."><i className="fa fa-cog"></i></span>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add Site</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <form className="form-auth-small" id='login-form' method='POST' onSubmit={this.handleSubmit}>
                        <div className="modal-body">
                          <div className="form-group">
                            <label className="control-label">Site Name</label>
                            <input type='text' className="form-control" id="site_name" name="site_name" placeholder="Site Name" />
                          </div>
                          <div className="form-group">
                            <label className="control-label">Unique Site Id</label>
                            <input type='text' className="form-control" id="unique_id" name="unique_id" placeholder="Unique Site Id" onChange={(e) => this.getUniqueId(e)} />
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-dark" data-dismiss="modal" id='closeModal'>Close</button>
                          <button type="submit" className="btn btn-success">Save changes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default Site;