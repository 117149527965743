import React from 'react';
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import {Link} from 'react-router-dom';
import './global';
import Footer from '../nav_src/Footer';

//jQuery libraries

import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"


//For API Requests
import axios from 'axios';

class Dashbaord extends React.Component {

  // State array variable to save and show data
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }
  componentDidMount() {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    //Get all users details in bootstrap table
    axios.post(global.passUrl+'get_sites', axiosConfig)
    .then((res) => {
      this.setState({ data: res.data });
      console.log(res.data);
    });
  }
  render() {
    
    return (
      <div className="wrapper">
        <DashNavbar />
        <div className='page_layout'>
          <div><Sidebar /></div>
          <div id="content">
            <div className="container-fluid">
              <div className="block-header">
                <div className="row">
                  <div className="col-lg-12 col-md-6 col-sm-12">
                    <h2>Dashbaord</h2>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                      <li className="breadcrumb-item active">Dashbaord</li>
                    </ul>
                  </div>
                </div>
                <div className="row mt-4">
                  { this.state.data.map((result,index) => {
                    return ( 
                      <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                        <Link to={`/site-details/${result.s_id}`}>
                          <div className="card top_widget cursor-pointer" id='color2'>
                            <div className="body" style={{ height: 100}}>
                              <div className="icon"><i className="fa fa-location-arrow location_mark"></i> </div>
                              <div className="content">
                                <div className="text mt-2 text-uppercase location_text text-white"> {result.site_name} </div>
                              </div>
                            </div>
                          </div>
                        </Link>  
                      </div> 
                    )
                  })}  
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default Dashbaord;