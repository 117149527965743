import React from 'react';
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//jQuery libraries

import 'jquery/dist/jquery.min.js';

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import {Link} from 'react-router-dom';
import './global';
//For API Requests
import axios from 'axios';
import Footer from '../nav_src/Footer';

class Conatct extends React.Component {
  
  // State array variable to save and show data
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      conatct_data: [],
    }
  }
  
  componentDidMount() {
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };
    //Get all users details in bootstrap table
    axios.post(global.passUrl+'get_sites', axiosConfig)
    .then((res) => {
      this.setState({ data: res.data });
    });

    axios.post(global.passUrl+'get_contacts', axiosConfig)
    .then((res) => {
      this.setState({ conatct_data: res.data });
    });

    
    $(document).ready(function () {
      setTimeout(function () {
        $('#example').DataTable();
      }, 1000);
    });
  }

  componentDidUpdate() {
    $('#example').DataTable().destroy();
  }

  handleSubmit = event => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    var postData = {
      site_id: data.get('site_id'),
      name: data.get('name'),
      email_id: data.get('email_id'),
      mobile_no: data.get('mobile_no'),
    };
    
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    }; 
    
    if (postData.site_id === '' && postData.name === '' && postData.email_id === '' && postData.mobile_no === '') {
      toast.dismiss()
      toast.error("All fields are Required!", {theme: "colored",}) 
    } else if (postData.site_id  === '') {
      toast.dismiss()
      toast.error("Please select site!", {theme: "colored",}) 
    } else if (postData.name  === '') {
      toast.dismiss()
      toast.error("Please select site!", {theme: "colored",}) 
    } else if (postData.email_id  === '') {
      toast.dismiss()
      toast.error("Email Id is Required!", {theme: "colored",}) 
    } else if (postData.mobile_no  === '') {
      toast.dismiss()
      toast.error("Mobile no is Required!", {theme: "colored",}) 
    } else {
      axios.post(global.passUrl+'add_contact', postData, axiosConfig)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.dismiss()
          toast.success("Conatct Craeted Successfully...", {theme: "colored",}) 
          setInterval(() => {
            window.location.href = '';
          }, 5000);
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Faill...", {theme: "colored",}) 
          setInterval(() => {
            window.location.href = '';
          }, 5000);
        }
        
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
    }
  }

  deleteRow(id,e) {
    e.preventDefault();    
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this contact!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        var postData = {
          c_id: id,
        };
        
        let axiosConfig = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
          }
        };    
    
        axios.post(global.passUrl+'delete_conatct', postData, axiosConfig)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            toast.dismiss()
            toast.success("Conatct deleted successfully...", {theme: "colored",}) 
            setInterval(() => {
              window.location.href = '';
            }, 5000);
          } else if (res.status === 400) {
            toast.dismiss()
            toast.success("Faill...", {theme: "colored",}) 
            setInterval(() => {
              window.location.href = '';
            }, 5000);
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
      } else {
        
      }
    });

  }

  onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(new RegExp("[0-9]").test(keyValue)))
      event.preventDefault();
    return;
  }

  render() {
    return (
      <div className="wrapper">
        <ToastContainer />
        <DashNavbar />
        <div className='page_layout'>
          <div><Sidebar /></div>
          <div id="content">
            <div className="container-fluid">
              <div className="block-header">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h2>Conatct</h2>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                      <li className="breadcrumb-item active">Conatct</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-right">
                    <span className="label btn-info rounded ml-2 cursor-pointer" data-toggle="modal" data-target="#exampleModal" ><i className="fa fa-plus"></i> Add Conatct</span>
                  </div>
                </div>
                <div className="row mt-4">
                  <table id="example" className="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Site Name</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.conatct_data.map((result,index) => {
                        return (
                          <tr key={index} >
                            <td>{index + 1}</td>
                            <td>{result.site_name}</td>
                            <td>{result.name}</td>
                            <td>{result.email_id}</td>
                            <td>{result.mobile_no}</td>
                            <td className='text-center' >
                              <div className='d-flex'>
                                <Link to={`/edit_conatct/${result.c_id}`}>
                                  <span className="label edit_btn rounded cursor-pointer"
                                  >Edit</span>
                                </Link>
                                <span className="label delete_btn rounded ml-2 cursor-pointer" onClick={(e) => this.deleteRow(result.c_id, e)}>Delete</span>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Add Conatct</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <form className="form-auth-small" id='login-form' method='POST' onSubmit={this.handleSubmit}>
                        <div className="modal-body">
                            <div className="form-group">
                              <label htmlFor="name" className="control-label">Site</label>  
                              <select className='form-control' id='site_id' name='site_id'>
                                <option value="">--- Select Site ---</option>
                                { this.state.data.map((result,index) => {
                                  return ( 
                                    <option key={index} value={result.s_id} >{result.site_name}</option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="name" className="control-label">Name</label>
                              <input type='text' className="form-control" id="name" name="name" placeholder="Name" />
                            </div>
                            <div className="form-group">
                              <label htmlFor="email_id" className="control-label">Email</label>
                              <input type='email' className="form-control" id="email_id" name="email_id" placeholder="Email Id" />
                            </div>
                            <div className="form-group">
                              <label htmlFor="mobile_no" className="control-label">Mobile No</label>
                              <input type='text' className="form-control" id="mobile_no" name="mobile_no" placeholder="Mobile no." maxLength={10} onKeyPress={this.onKeyPressEvent}/>
                            </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-dark" data-dismiss="modal" id='closeModal'>Close</button>
                          <button type="submit" className="btn btn-success">Save changes</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default Conatct;