import React from 'react'
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate,Link } from 'react-router-dom';
import './global';
import Footer from '../nav_src/Footer';

//For API Requests
import axios from 'axios';

export default function EditSite() {
    
    const navigate = useNavigate();
    const [site_name, setSiteName] = useState("");
    const [unique_id, setUniqueId] = useState("");
    const [site_id, setSiteId] = useState('');
    const [site_id_check, setSitecheck] = useState(false);

    const paramsData = useParams();

    useEffect(() => {
        setDetails(paramsData.id);
    });

    const setDetails= (id) =>{
        if (!site_id_check) {
            var postData = {
                s_id: id,
            };
    
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            };
    
            axios.post(global.passUrl+'edit_site', postData, axiosConfig)
            .then((res) => {
                if (res.status === 200) {
                    setSiteName(res.data[0].site_name);
                    setUniqueId(res.data[0].unique_id);
                    setSiteId(id);
                    setSitecheck(true);
                } else if (res.status === 400) {
                    toast.dismiss()
                    toast.success("Faill...",{ theme: "colored", })
                }
            })
            .catch((err) => {
                toast.dismiss()
                toast.success("Updated Successfully...",{ theme: "colored", })
            })
        }
    }

    const updateSite = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        var postData = {
            site_name: data.get('site_name'),
            site_id: data.get('site_id'),
        };

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };

        axios.post(global.passUrl+'update_site', postData, axiosConfig)
            .then((res) => {
                if (res.status === 200) {
                    toast.dismiss()
                    toast.success("Updated Successfully...",{ theme: "colored", })
                    setInterval(() => {
                        navigate('/site') 
                    }, 5000);

                } else if (res.status === 400) {
                    window.location.href = '';
                }
                console.log("RESPONSE RECEIVED: ", res);
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })

    }

    return (
        <div className="wrapper">
            <ToastContainer />
            <DashNavbar />
            <div className='page_layout'>
                <div><Sidebar /></div>
                <div id="content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h2>Edit Site</h2>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                                        <li className="breadcrumb-item active">Edit Site</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 text-right">
                                    <Link to='/site' >
                                        <span className="label btn-info rounded ml-2 cursor-pointer" ><i className="fa fa-step-backward"></i> Back</span>
                                    </Link>
                                </div>
                            </div>
                            <hr className='colorLine' />
                            <div className="row mt-4">
                                <div className="card1 col-sm-8">
                                    <div className="card-header">
                                        Edit Site
                                    </div>
                                    <div className="card-body p-0">
                                        <form className="form-auth-small" id='login-form' method='POST' onSubmit={updateSite}>
                                            <input type='hidden' className="form-control" id="site_id" name="site_id" value={site_id} />

                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="form-group col-6">
                                                        <label htmlFor="site_name" className="control-label">Site Name: </label>
                                                        <input type='text' className="form-control" id="site_name" name="site_name" placeholder="Site Name" value={site_name} onChange={(e) => setSiteName(e.target.value)} />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label htmlFor="unique_id" className="control-label">Unique Site Id: </label>
                                                        <input type='text' className="form-control" id="unique_id" name="unique_id" placeholder="Site Name" value={unique_id} readOnly/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right">
                                                <button type="submit" className="btn btn-success">Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
