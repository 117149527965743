import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginReg from "./component/auth/login";
import Layout from "./component/nav_src/Layout";
import Dashboard from "./component/pages/Dashboard";
import Site from './component/pages/Site';
import EditSite from './component/pages/EditSite';
import Conatct from './component/pages/Conatct';
import EditConatct from './component/pages/EditConatct';
import ChangePassword from './component/pages/ChangePassword';
import ResetPassword from './component/auth/ResetPassword';
import NewPassword from './component/auth/NewPassword';
import SiteDetails from './component/pages/SiteDetails';
import SiteDetailsByFileName from './component/pages/SiteDetailsByFileName';
import ViewReport from './component/pages/ViewReport';
import Contacts from './component/pages/Contacts';
import SuperAdminLogin from './component/SuperAdmin/SuperAdminLogin';
import ConfigTool from './component/SuperAdmin/ConfigTool';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LoginReg />} />          
          <Route path="/reset-password" element={<ResetPassword />} />          
          <Route path="/new-password/:email/:id" element={<NewPassword />} />
          <Route path="/super-admin-login" element={<SuperAdminLogin />} />
        </Route>       
        <Route path="/">
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/site-details/:site_id" element={<SiteDetails />} />
          <Route path="/file_site_details/:site_id/:file_id" element={<SiteDetailsByFileName />} />
          <Route path="/view-report/:site_id" element={<ViewReport />} />
          <Route path="/site" element={<Site />} />
          <Route path="/edit_site/:id" element={<EditSite />} />
          <Route path="/conatct" element={<Conatct />} />
          <Route path="/contacts/:id" element={<Contacts />} />
          <Route path="/edit_conatct/:id" element={<EditConatct />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/config-tool" element={<ConfigTool />} />
        </Route> 
        <Route path="*" element={<h1 className="text-center m-auto">Error 404 Page not found !!</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
