import React,{ useState,useEffect } from 'react';
import DashNavbar from "../nav_src/AdminSidebar";
import Sidebar from "../nav_src/AdminTopNavbar";
import Footer from '../nav_src/Footer';
import '../../assets/css/App.css';
import '../pages/global';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../assets/Images/default.gif';

export default function ConfigTool() {

    const [Loadding, setLoadding] = useState(false);

    const [conf_id, setConfId] = useState();
    const [wheel_alert, setWheelAlert] = useState();
    const [axle_alert, setAxleAlert] = useState();
    const [loco_distance, setLocoDistance] = useState();
    const [loco_wheel_distance, setLocoWheelDistance] = useState();
    const [lococoach_wheel_distance, setLocoCoachWheelDistance] = useState();
    const [coach_wheel_distance, setCoachWheelDistance] = useState();

    const [wrh_min_val, setWrhMinVal] = useState();
    const [wrh_max_val, setWrhMaxVal] = useState();
    const [wrh_multiplier, setWrhMultiplier] = useState();
    const [wrh_offset, setWrhOffset] = useState();

    const [wrl_min_val, setWrlMinVal] = useState();
    const [wrl_max_val, setWrlMaxVal] = useState();
    const [wrl_multiplier, setWrlMultiplier] = useState();
    const [wrl_offset, setWrlOffset] = useState();

    const [drh_min_val, setDrhMinVal] = useState();
    const [drh_max_val, setDrhMaxVal] = useState();
    const [drh_multiplier, setDrhMultiplier] = useState();
    const [drh_offset, setDrhOffset] = useState();
    
    const [drl_min_val, setDrlMinVal] = useState();
    const [drl_max_val, setDrlMaxVal] = useState();
    const [drl_multiplier, setDrlMultiplier] = useState();
    const [drl_offset, setDrlOffset] = useState();

    const [arh_min_val, setArhMinVal] = useState();
    const [arh_max_val, setArhMaxVal] = useState();
    const [arh_multiplier, setArhMultiplier] = useState();
    const [arh_offset, setArhOffset] = useState();
    
    const [arl_min_val, setArlMinVal] = useState();
    const [arl_max_val, setArlMaxVal] = useState();
    const [arl_multiplier, setArlMultiplier] = useState();
    const [arl_offset, setArlOffset] = useState();

    const [wlh_min_val, setWlhMinVal] = useState();
    const [wlh_max_val, setWlhMaxVal] = useState();
    const [wlh_multiplier, setWlhMultiplier] = useState();
    const [wlh_offset, setWlhOffset] = useState();
    
    const [wll_min_val, setWllMinVal] = useState();
    const [wll_max_val, setWllMaxVal] = useState();
    const [wll_multiplier, setWllMultiplier] = useState();
    const [wll_offset, setWllOffset] = useState();

    const [dlh_min_val, setDlhMinVal] = useState();
    const [dlh_max_val, setDlhMaxVal] = useState();
    const [dlh_multiplier, setDlhMultiplier] = useState();
    const [dlh_offset, setDlhOffset] = useState();
    
    const [dll_min_val, setDllMinVal] = useState();
    const [dll_max_val, setDllMaxVal] = useState();
    const [dll_multiplier, setDllMultiplier] = useState();
    const [dll_offset, setDllOffset] = useState();

    const [alh_min_val, setAlhMinVal] = useState();
    const [alh_max_val, setAlhMaxVal] = useState();
    const [alh_multiplier, setAlhMultiplier] = useState();
    const [alh_offset, setAlhOffset] = useState();
    
    const [all_min_val, setAllMinVal] = useState();
    const [all_max_val, setAllMaxVal] = useState();
    const [all_multiplier, setAllMultiplier] = useState();
    const [all_offset, setAllOffset] = useState();

    const [display_style, setDisplayStyle] = useState("d-none");

    const [site_data, setSiteData] = useState([]);


    useEffect(() => {
        let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
            }
        };

        axios.post(global.passUrl+'get_sites', axiosConfig)
        .then((res) => {
            setSiteData(res.data);            
        });
    }, [])

    const handleSubmit = (e) => {
        setLoadding(true);
        
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        var postData = {
            site_id: data.get('site_id'),
            conf_id: data.get('conf_id'),
            wheel_alert: data.get('wheel_alert'),
            axle_alert: data.get('axle_alert'),
            loco_distance: data.get('loco_distance'),
            loco_wheel_distance: data.get('loco_wheel_distance'),
            lococoach_wheel_distance: data.get('lococoach_wheel_distance'),
            coach_wheel_distance: data.get('coach_wheel_distance'),

            wrh_min_val: data.get('wrh_min_val'),
            wrh_max_val: data.get('wrh_max_val'),
            wrh_multiplier: data.get('wrh_multiplier'),
            wrh_offset: data.get('wrh_offset'),

            wrl_min_val: data.get('wrl_min_val'),
            wrl_max_val: data.get('wrl_max_val'),
            wrl_multiplier: data.get('wrl_multiplier'),
            wrl_offset: data.get('wrl_offset'),

            drh_min_val: data.get('drh_min_val'),
            drh_max_val: data.get('drh_max_val'),
            drh_multiplier: data.get('drh_multiplier'),
            drh_offset: data.get('drh_offset'),

            drl_min_val: data.get('drl_min_val'),
            drl_max_val: data.get('drl_max_val'),
            drl_multiplier: data.get('drl_multiplier'),
            drl_offset: data.get('drl_offset'),

            arh_min_val: data.get('arh_min_val'),
            arh_max_val: data.get('arh_max_val'),
            arh_multiplier: data.get('arh_multiplier'),
            arh_offset: data.get('arh_offset'),

            arl_min_val: data.get('arl_min_val'),
            arl_max_val: data.get('arl_max_val'),
            arl_multiplier: data.get('arl_multiplier'),
            arl_offset: data.get('arl_offset'),

            wlh_min_val: data.get('wlh_min_val'),
            wlh_max_val: data.get('wlh_max_val'),
            wlh_multiplier: data.get('wlh_multiplier'),
            wlh_offset: data.get('wlh_offset'),

            wll_min_val: data.get('wll_min_val'),
            wll_max_val: data.get('wll_max_val'),
            wll_multiplier: data.get('wll_multiplier'),
            wll_offset: data.get('wll_offset'),

            dlh_min_val: data.get('dlh_min_val'),
            dlh_max_val: data.get('dlh_max_val'),
            dlh_multiplier: data.get('dlh_multiplier'),
            dlh_offset: data.get('dlh_offset'),

            dll_min_val: data.get('dll_min_val'),
            dll_max_val: data.get('dll_max_val'),
            dll_multiplier: data.get('dll_multiplier'),
            dll_offset: data.get('dll_offset'),

            alh_min_val: data.get('alh_min_val'),
            alh_max_val: data.get('alh_max_val'),
            alh_multiplier: data.get('alh_multiplier'),
            alh_offset: data.get('alh_offset'),

            all_min_val: data.get('all_min_val'),
            all_max_val: data.get('all_max_val'),
            all_multiplier: data.get('all_multiplier'),
            all_offset: data.get('all_offset'),
        };
        
        let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
            }
        };

        axios.post(global.passUrl+'update_config', postData, axiosConfig)
        .then((res) => {
            setLoadding(false);
            if (res.status === 200) {
            if (res.data.status === 'success' ) {
                toast.dismiss()
                toast.success(res.data.msg, {theme: "colored",}) 
            } else {
                toast.dismiss()
                toast.error(res.data.msg, {theme: "colored",}) 
            }
            } else if (res.status === 400) {
            toast.dismiss()
            toast.error("Fail!!", {theme: "colored",}) 
            }
        }).catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    }
    
    const getSelectedVal = (val) => {
        if(val === ""){
            setDisplayStyle("d-none");
            setConfId("");
            setWheelAlert("");
            setAxleAlert("");
            setLocoDistance("");
            setLocoWheelDistance("");
            setLocoCoachWheelDistance("");
            setCoachWheelDistance("");

            setWrhMinVal("");
            setWrhMaxVal("");
            setWrhMultiplier("");
            setWrhOffset("");

            setWrlMinVal("");
            setWrlMaxVal("");
            setWrlMultiplier("");
            setWrlOffset("");

            setDrhMinVal("");
            setDrhMaxVal("");
            setDrhMultiplier("");
            setDrhOffset("");

            setDrlMinVal("");
            setDrlMaxVal("");
            setDrlMultiplier("");
            setDrlOffset("");

            setArhMinVal("");
            setArhMaxVal("");
            setArhMultiplier("");
            setArhOffset("");

            setArlMinVal("");
            setArlMaxVal("");
            setArlMultiplier("");
            setArlOffset("");

            setWlhMinVal("");
            setWlhMaxVal("");
            setWlhMultiplier("");
            setWlhOffset("");

            setWllMinVal("");
            setWllMaxVal("");
            setWllMultiplier("");
            setWllOffset("");

            setDlhMinVal("");
            setDlhMaxVal("");
            setDlhMultiplier("");
            setDlhOffset("");

            setDllMinVal("");
            setDllMaxVal("");
            setDllMultiplier("");
            setDllOffset("");

            setAlhMinVal("");
            setAlhMaxVal("");
            setAlhMultiplier("");
            setAlhOffset("");

            setAllMinVal("");
            setAllMaxVal("");
            setAllMultiplier("");
            setAllOffset("");
        }else{
            setDisplayStyle("d-block");
            let axiosConfig = {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  "Access-Control-Allow-Origin": "*",
                }
            };
            var postData = {
                site_id: val
            };
            axios.post(global.passUrl+'get_update_config_data', postData , axiosConfig).then((res) => {  
                if (res.data.status === 'error') {
                    setConfId("");
                    setWheelAlert("");
                    setAxleAlert("");
                    setLocoDistance("");
                    setLocoWheelDistance("");
                    setLocoCoachWheelDistance("");
                    setCoachWheelDistance("");
    
                    setWrhMinVal("");
                    setWrhMaxVal("");
                    setWrhMultiplier("");
                    setWrhOffset("");
    
                    setWrlMinVal("");
                    setWrlMaxVal("");
                    setWrlMultiplier("");
                    setWrlOffset("");
    
                    setDrhMinVal("");
                    setDrhMaxVal("");
                    setDrhMultiplier("");
                    setDrhOffset("");
    
                    setDrlMinVal("");
                    setDrlMaxVal("");
                    setDrlMultiplier("");
                    setDrlOffset("");
    
                    setArhMinVal("");
                    setArhMaxVal("");
                    setArhMultiplier("");
                    setArhOffset("");
    
                    setArlMinVal("");
                    setArlMaxVal("");
                    setArlMultiplier("");
                    setArlOffset("");
    
                    setWlhMinVal("");
                    setWlhMaxVal("");
                    setWlhMultiplier("");
                    setWlhOffset("");
    
                    setWllMinVal("");
                    setWllMaxVal("");
                    setWllMultiplier("");
                    setWllOffset("");
    
                    setDlhMinVal("");
                    setDlhMaxVal("");
                    setDlhMultiplier("");
                    setDlhOffset("");
    
                    setDllMinVal("");
                    setDllMaxVal("");
                    setDllMultiplier("");
                    setDllOffset("");
    
                    setAlhMinVal("");
                    setAlhMaxVal("");
                    setAlhMultiplier("");
                    setAlhOffset("");
    
                    setAllMinVal("");
                    setAllMaxVal("");
                    setAllMultiplier("");
                    setAllOffset("");
                } else {
                    setConfId(res.data.allRowsData[0].conf_id);
                    setWheelAlert(res.data.allRowsData[0].wheel_alert);
                    setAxleAlert(res.data.allRowsData[0].axle_alert);
                    setLocoDistance(res.data.allRowsData[0].loco_distance);
                    setLocoWheelDistance(res.data.allRowsData[0].loco_wheel_distance);
                    setLocoCoachWheelDistance(res.data.allRowsData[0].lococoach_wheel_distance);
                    setCoachWheelDistance(res.data.allRowsData[0].coach_wheel_distance);
    
                    setWrhMinVal(res.data.allRowsData[0].wrh_min_val);
                    setWrhMaxVal(res.data.allRowsData[0].wrh_max_val);
                    setWrhMultiplier(res.data.allRowsData[0].wrh_multiplier);
                    setWrhOffset(res.data.allRowsData[0].wrh_offset);
    
                    setWrlMinVal(res.data.allRowsData[0].wrl_min_val);
                    setWrlMaxVal(res.data.allRowsData[0].wrl_max_val);
                    setWrlMultiplier(res.data.allRowsData[0].wrl_multiplier);
                    setWrlOffset(res.data.allRowsData[0].wrl_offset);
    
                    setDrhMinVal(res.data.allRowsData[0].drh_min_val);
                    setDrhMaxVal(res.data.allRowsData[0].drh_max_val);
                    setDrhMultiplier(res.data.allRowsData[0].drh_multiplier);
                    setDrhOffset(res.data.allRowsData[0].drh_offset);
    
                    setDrlMinVal(res.data.allRowsData[0].drl_min_val);
                    setDrlMaxVal(res.data.allRowsData[0].drl_max_val);
                    setDrlMultiplier(res.data.allRowsData[0].drl_multiplier);
                    setDrlOffset(res.data.allRowsData[0].drl_offset);
    
                    setArhMinVal(res.data.allRowsData[0].arh_min_val);
                    setArhMaxVal(res.data.allRowsData[0].arh_max_val);
                    setArhMultiplier(res.data.allRowsData[0].arh_multiplier);
                    setArhOffset(res.data.allRowsData[0].arh_offset);
    
                    setArlMinVal(res.data.allRowsData[0].arl_min_val);
                    setArlMaxVal(res.data.allRowsData[0].arl_max_val);
                    setArlMultiplier(res.data.allRowsData[0].arl_multiplier);
                    setArlOffset(res.data.allRowsData[0].arl_offset);
    
                    setWlhMinVal(res.data.allRowsData[0].wlh_min_val);
                    setWlhMaxVal(res.data.allRowsData[0].wlh_max_val);
                    setWlhMultiplier(res.data.allRowsData[0].wlh_multiplier);
                    setWlhOffset(res.data.allRowsData[0].wlh_offset);
    
                    setWllMinVal(res.data.allRowsData[0].wll_min_val);
                    setWllMaxVal(res.data.allRowsData[0].wll_max_val);
                    setWllMultiplier(res.data.allRowsData[0].wll_multiplier);
                    setWllOffset(res.data.allRowsData[0].wll_offset);
    
                    setDlhMinVal(res.data.allRowsData[0].dlh_min_val);
                    setDlhMaxVal(res.data.allRowsData[0].wlh_max_val);
                    setDlhMultiplier(res.data.allRowsData[0].wlh_multiplier);
                    setDlhOffset(res.data.allRowsData[0].wlh_offset);
    
                    setDllMinVal(res.data.allRowsData[0].dll_min_val);
                    setDllMaxVal(res.data.allRowsData[0].dll_max_val);
                    setDllMultiplier(res.data.allRowsData[0].dll_multiplier);
                    setDllOffset(res.data.allRowsData[0].dll_offset);
    
                    setAlhMinVal(res.data.allRowsData[0].alh_min_val);
                    setAlhMaxVal(res.data.allRowsData[0].alh_max_val);
                    setAlhMultiplier(res.data.allRowsData[0].alh_multiplier);
                    setAlhOffset(res.data.allRowsData[0].alh_offset);
    
                    setAllMinVal(res.data.allRowsData[0].all_min_val);
                    setAllMaxVal(res.data.allRowsData[0].all_max_val);
                    setAllMultiplier(res.data.allRowsData[0].all_multiplier);
                    setAllOffset(res.data.allRowsData[0].all_offset);
                }                    
            }).catch((err) => {
                setLoadding(false);
                console.log("AXIOS ERROR: ", err);
            })

        }
    }

    return (
        <div className="wrapper">
            <ToastContainer />
            <DashNavbar />
            <div className='page_layout' >
                <div><Sidebar /></div>
                <div id="content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                    <h2>Config Tool</h2>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/config-tool"><i className="fa fa-home"></i></a></li>
                                        <li className="breadcrumb-item active">Config Tool</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-12 pl-0 mt-4">
                                <form className="form-auth-small" method='POST' onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className="form-group col-sm-9"></div>
                                        <div className="form-group col-sm-3">
                                            <label htmlFor="name" className="control-label">Site</label>  
                                            <select className='form-select' id='site_id' name='site_id' onChange={(e) => getSelectedVal(e.target.value)} >
                                                <option value="">------ Select Site ------</option>
                                                { site_data.map((site, index) => (
                                                    <option key={index} value={site.s_id} >{site.site_name}</option>
                                                )) }
                                            </select>
                                        </div>
                                    </div>
                                    <div className={display_style}>
                                        <input className='form-control' type="hidden" name='conf_id' value={conf_id} />
                                        <div className='row'>
                                            <div className='form-group col-sm-6'>
                                                <label>Wheel Alert: </label>
                                                <input className='form-control' type="number" name='wheel_alert' placeholder='Wheel Alert' value={wheel_alert} onChange={(e) => setWheelAlert(e.target.value)} required/>
                                            </div>
                                            <div className='form-group col-sm-6'>
                                                <label>Axle Alert: </label>
                                                <input className='form-control' type="number" name='axle_alert' placeholder='Axle Alert' value={axle_alert} onChange={(e) => setAxleAlert(e.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-sm-3'>
                                                <label>Loco Distance: </label>
                                                <input className='form-control' type="number" name='loco_distance' placeholder='Loco Distance' value={loco_distance} onChange={(e) => setLocoDistance(e.target.value)} required/>
                                            </div>
                                            <div className='form-group col-sm-3'>
                                                <label>Loco To Wheel Distance: </label>
                                                <input className='form-control' type="number" name='loco_wheel_distance' placeholder='Loco To Wheel Distance' value={loco_wheel_distance} onChange={(e) => setLocoWheelDistance(e.target.value)} required/>
                                            </div>
                                            <div className='form-group col-sm-3'>
                                                <label>Loco To Coach Wheel Distance: </label>
                                                <input className='form-control' type="number" name='lococoach_wheel_distance' placeholder='Loco To Wheel Distance' value={lococoach_wheel_distance} onChange={(e) => setLocoCoachWheelDistance(e.target.value)} required/>
                                            </div>
                                            <div className='form-group col-sm-3'>
                                                <label>Coach Wheel Distance: </label>
                                                <input className='form-control' type="number" name='coach_wheel_distance' placeholder='Coach Wheel Distance' value={coach_wheel_distance} onChange={(e) => setCoachWheelDistance(e.target.value)} required/>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-striped config-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" >Sensor Name</th>
                                                        <th scope="col">Sensor Type</th>
                                                        <th scope="col">Connected To</th>
                                                        <th scope="col" width="15%">Min Value</th>
                                                        <th scope="col">Max Value</th>
                                                        <th scope="col">Multiplier</th>
                                                        <th scope="col">Offset</th>
                                                        <th scope="col">Last DP Value</th>
                                                        <th scope="col">Last DP Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Right Wheel - <br/> High (WRH)</td>
                                                        <td>1ms</td>
                                                        <td>Right Axle</td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrh_min_val' placeholder='WRH Min Value' value={wrh_min_val} onChange={(e) => setWrhMinVal(e.target.value)}  />
                                                            <small>Will set the minimum <br/> value for sensor</small>
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrh_max_val' placeholder='WRH Max Value' value={wrh_max_val} onChange={(e) => setWrhMaxVal(e.target.value)} />
                                                            <small>Will set the maximum <br/> value for sensor</small>
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrh_multiplier' placeholder='WRH Max Multiplier' value={wrh_multiplier} onChange={(e) => setWrhMultiplier(e.target.value)} />
                                                            <small>Will multiply with <br/> sensor value. Must be >= 0</small>
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrh_offset' placeholder='WRH Max Offset' value={wrh_offset} onChange={(e) => setWrhOffset(e.target.value)} />
                                                            <small>Will add/subtract the value <br/> from sensor.Can be <br/> positive/negative</small>
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Right Wheel - <br/> Low (WRL)</td>
                                                        <td>3ms</td>
                                                        <td>Right Wheel</td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrl_min_val' placeholder='WRL Min Value' value={wrl_min_val} onChange={(e) => setWrlMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrl_max_val' placeholder='WRL Max Value' value={wrl_max_val} onChange={(e) => setWrlMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrl_multiplier' placeholder='WRL Max Multiplier' value={wrl_multiplier} onChange={(e) => setWrlMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wrl_offset' placeholder='WRL Max Offset' value={wrl_offset} onChange={(e) => setWrlOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Right Disk  - <br/> High (DRH)</td>
                                                        <td>3ms</td>
                                                        <td>Right Disk </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drh_min_val' placeholder='DRH Min Value' value={drh_min_val} onChange={(e) => setDrhMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drh_max_val' placeholder='DRH Max Value' value={drh_max_val} onChange={(e) => setDrhMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drh_multiplier' placeholder='DRH Max Multiplier' value={drh_multiplier} onChange={(e) => setDrhMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drh_offset' placeholder='DRH Max Offset' value={drh_offset} onChange={(e) => setDrhOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Right Disk  - <br/> Low (DRL)</td>
                                                        <td>3ms</td>
                                                        <td>Right Axle </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drl_min_val' placeholder='DRL Min Value' value={drl_min_val} onChange={(e) => setDrlMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drl_max_val' placeholder='DRL Max Value' value={drl_max_val} onChange={(e) => setDrlMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drl_multiplier' placeholder='DRL Max Multiplier' value={drl_multiplier} onChange={(e) => setDrlMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='drl_offset' placeholder='DRL Max Offset' value={drl_offset} onChange={(e) => setDrlOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Right Axle  - <br/> High (ARH)</td>
                                                        <td>3ms</td>
                                                        <td>Right Axle </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arh_min_val' placeholder='ARH Min Value' value={arh_min_val} onChange={(e) => setArhMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arh_max_val' placeholder='ARH Max Value' value={arh_max_val} onChange={(e) => setArhMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arh_multiplier' placeholder='ARH Max Multiplier' value={arh_multiplier} onChange={(e) => setArhMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arh_offset' placeholder='ARH Max Offset' value={arh_offset} onChange={(e) => setArhOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Right Axle  - <br/> Low (ARL)</td>
                                                        <td>3ms</td>
                                                        <td>Right Axle </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arl_min_val' placeholder='ARL Min Value' value={arl_min_val} onChange={(e) => setArlMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arl_max_val' placeholder='ARL Max Value' value={arl_max_val} onChange={(e) => setArlMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arl_multiplier' placeholder='ARL Max Multiplier' value={arl_multiplier} onChange={(e) => setArlMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='arl_offset' placeholder='ARL Max Offset' value={arl_offset} onChange={(e) => setArlOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Left Wheel  - <br/> High (WLH)</td>
                                                        <td>3ms</td>
                                                        <td>Left Wheel </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wlh_min_val' placeholder='WLH Min Value' value={wlh_min_val} onChange={(e) => setWlhMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wlh_max_val' placeholder='WLH Max Value' value={wlh_max_val} onChange={(e) => setWlhMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wlh_multiplier' placeholder='WLH Max Multiplier' value={wlh_multiplier} onChange={(e) => setWlhMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wlh_offset' placeholder='WLH Max Offset' value={wlh_offset} onChange={(e) => setWlhOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Left Wheel  - <br/> Low  (WLL)</td>
                                                        <td>3ms</td>
                                                        <td>Left Axle </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wll_min_val' placeholder='WLL Min Value' value={wll_min_val} onChange={(e) => setWllMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wll_max_val' placeholder='WLL Max Value' value={wll_max_val} onChange={(e) => setWllMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wll_multiplier' placeholder='WLL Max Multiplier' value={wll_multiplier} onChange={(e) => setWllMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='wll_offset' placeholder='WLL Max Offset' value={wll_offset} onChange={(e) => setWllOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Left Disk  - <br/> High (DLH)</td>
                                                        <td>3ms</td>
                                                        <td>Left Disk </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dlh_min_val' placeholder='DLH Min Value' value={dlh_min_val} onChange={(e) => setDlhMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dlh_max_val' placeholder='DLH Max Value' value={dlh_max_val} onChange={(e) => setDlhMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dlh_multiplier' placeholder='DLH Max Multiplier' value={dlh_multiplier} onChange={(e) => setDlhMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dlh_offset' placeholder='DLH Max Offset' value={dlh_offset} onChange={(e) => setDlhOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Left Disk  - <br/> Low (DLL)</td>
                                                        <td>3ms</td>
                                                        <td>Left Axle </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dll_min_val' placeholder='DLL Min Value' value={dll_min_val} onChange={(e) => setDllMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dll_max_val' placeholder='DLL Max Value' value={dll_max_val} onChange={(e) => setDllMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dll_multiplier' placeholder='DLL Max Multiplier' value={dll_multiplier} onChange={(e) => setDllMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='dll_offset' placeholder='DLL Max Offset' value={dll_offset} onChange={(e) => setDllOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Left Axle  - <br/> High (ALH)</td>
                                                        <td>1ms</td>
                                                        <td>Left Axle </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='alh_min_val' placeholder='ALH Min Value' value={alh_min_val} onChange={(e) => setAlhMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='alh_max_val' placeholder='ALH Max Value' value={alh_max_val} onChange={(e) => setAlhMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='alh_multiplier' placeholder='ALH Max Multiplier' value={alh_multiplier} onChange={(e) => setAlhMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='alh_offset' placeholder='ALH Max Offset' value={alh_offset} onChange={(e) => setAlhOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Left Axle  - <br/> Low (ALL)</td>
                                                        <td>3ms</td>
                                                        <td>Left Axle </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='all_min_val' placeholder='ALL Min Value' value={all_min_val} onChange={(e) => setAllMinVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='all_max_val' placeholder='ALL Max Value' value={all_max_val} onChange={(e) => setAllMaxVal(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='all_multiplier' placeholder='ALL Max Multiplier' value={all_multiplier} onChange={(e) => setAllMultiplier(e.target.value)} />
                                                        </td>
                                                        <td>
                                                            <input className='form-control' type="number" name='all_offset' placeholder='ALL Max Offset' value={all_offset} onChange={(e) => setAllOffset(e.target.value)} />
                                                        </td>
                                                        <td className="text-center" >N/A</td>
                                                        <td className="text-center" >N/A</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='row mb-5'>
                                            <div className='col-sm-5'></div>
                                            <div className='col-sm-2'>
                                                <button type="submit" className="btn btn-dark btn-block" >Submit </button>
                                                {Loadding ? <img src={Loader} className="App-logo" alt="logo" style={{ width: 30, marginLeft: 15}}/> : ''} 
                                            </div>
                                            <div className='col-sm-5'></div>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}
