import '../../assets/css/main.css';
import '../../assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/bootstrap.css';
import logo from '../../assets/Images/kr.jpeg';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../pages/global';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import EyeIcon from '../../assets/Images/eye.png';
import hiddenEye from '../../assets/Images/hidden.png';


export default function NewPassword() {

  const navigate = useNavigate();
  const [newIsPasswordShow, setNewIsPasswordShow] = useState(false);
  const [confirmIsPasswordShow, setConfirmIsPasswordShow] = useState(false);
  const [site_id_check, setSitecheck] = useState(false);

  const toggleNewIsPasswordShowValue = () => {
    setNewIsPasswordShow(!newIsPasswordShow);
  };

  const toggleConfirmIsPasswordShowValue = () => {
    setConfirmIsPasswordShow(!confirmIsPasswordShow);
  };

  const paramsData = useParams();

  useEffect(() => {
    setDetails(paramsData.email, paramsData.id);
  });

  const setDetails = (email, id) => {
    if (!site_id_check) {
      var postData = {
        token: id,
        email: email,
      };

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      axios.post(global.passUrl + 'check_reset_password_url', postData, axiosConfig)
        .then((res) => {
          setSitecheck(true);
          if (res.data.status === 'error') {
            toast.dismiss()
            toast.error(res.data.msg, {theme: "colored",})
          }
        })
        .catch((err) => {
          toast.dismiss()
          toast.error("Fail!!", {theme: "colored",})
        })
    }
  }

  const updatePassword = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    var postData = {
      new_password: data.get('new_password'),
      email: paramsData.email
    };  
    
    if (postData.new_password === data.get('confirm_password')) {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };
      const url = global.passUrl + 'update_new_password';
      axios.post(url, postData, axiosConfig)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status === 'success') {
              toast.dismiss()
              toast.success(res.data.msg,{ theme: "colored", })
              setInterval(() => {
                navigate('/')
            }, 5000);
            } else {
              toast.dismiss()
              toast.error(res.data.msg, {theme: "colored",})
            }
          } else if (res.status === 400) {
            
            toast.dismiss()
            toast.error("Fail!!", {theme: "colored",})
          }
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        })
    } else {
      toast.dismiss()
      toast.error("Password & Confirm Password is does not match.", {theme: "colored",})
    }
  }

  return (
    <div className="App">
      <ToastContainer />
      <div id="wrapper" className="theme-cyan">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="card p-0">
                <div className="top1">
                  <img src={logo} className="App-logo" alt="logo" style={{ width: 130 }} />
                </div>
                <div className="header pl-0 pr-0">
                  <p className="card-subtitle line-on-side text-muted text-center font-medium-1"><span> Set Password </span></p>
                </div>
                <div className="body">
                  <form className="form-auth-small" id='login-form' method='POST' onSubmit={updatePassword}>
                    <div className="form-group col-sm-12 text-left p-0">
                      <label htmlFor="name" className="control-label">Password: </label>
                      <input type={newIsPasswordShow ? 'text' : 'password'} className="form-control" id="new_password" name="new_password" placeholder="New Password" required />
                      <div className='eye-icon' onClick={toggleNewIsPasswordShowValue}>
                        {newIsPasswordShow ? <img src={EyeIcon} alt="logo" /> : <img src={hiddenEye} alt="logo" />}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 text-left p-0">
                      <label htmlFor="name" className="control-label">Confirm Password: </label>
                      <input type={confirmIsPasswordShow ? 'text' : 'password'} className="form-control" id="confirm_password" name="confirm_password" placeholder="Confirm Password" required />
                      <div className='eye-icon' onClick={toggleConfirmIsPasswordShowValue}>
                        {confirmIsPasswordShow ? <img src={EyeIcon} alt="logo" /> : <img src={hiddenEye} alt="logo" />}
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary btn-block" >Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer footer-static footer-dark navbar-border footerStyle">
          <p className="clearfix blue-grey1 lighten-2 text-sm-center mb-0 px-2">
            <span className="text-white float-md-center d-block d-md-inline-block">© 2025, Powered By <a href="https://www.ceryletech.com" target="_blank" rel="noreferrer" className='footer_link'>CeryleTech Innovotives LLP</a>.</span>
          </p>
        </div>
      </div>
    </div>
  )
}
