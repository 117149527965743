import React from 'react'
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import './global';
import Footer from '../nav_src/Footer';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import logo from '../../assets/Images/kr.jpeg';
import logo1 from '../../assets/Images/Indian_Railway.png';
import Loader from '../../assets/Images/default.gif';
//For API Requests
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SiteDetails() {
    const paramsData = useParams();

    const [loading, setLoadding] = useState(false);
    const [data_check, setDatacheck] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [site_id, setSiteId] = useState();

    const [report_data, setReportData] = useState([]);
    const [report_data_count, setReportDataCount] = useState();
    const [get_filter, setfileteOk] = useState('N');
    const [stock_type, setStockType] = useState('All');
    const [wheel_alert, setWheelAlert] = useState('All');
    const [axle_alert, setAxleAlert] = useState('All');

    const [change_satrt_date, setChangeStartDate] = useState();
    const [change_end_date, setChangeEndDate] = useState();

    var today_date = new Date();
    const year = today_date.getFullYear();
    const month = ("0" + (today_date.getMonth() + 1)).slice(-2);
    const day = ("0" + today_date.getDate()).slice(-2);


    useEffect(() => {        
        setDetails(paramsData.site_id);
    });

    const setDetails = async(id) => {
        setLoadding(true)
        setSiteId(id)

        if (!data_check) {
            var postData = {
                site_id: id,
            };

            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            };

            await axios.post(global.passUrl + 'get_report_details', postData, axiosConfig).then((res) => {
                setLoadding(false)
                if (res.status === 200) {
                    if (res.data.status === 'error') {
                        setDatacheck(true)
                        setReportData([])
                        setReportDataCount(0)
                        toast.dismiss()
                        toast.error(res.data.msg, {theme: "colored"}) 
                    } else {
                        setDatacheck(true)
                        setReportData(res.data)
                        setReportDataCount(1)
                    }
                } else if (res.status === 400) {
                    toast.dismiss()
                    toast.error("Faill!!", {theme: "colored",}) 
                }
            }).catch((err) => {
                toast.dismiss()
                toast.error(err, {theme: "colored",}) 
            })
        }
    }

    const updateSite = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        var postData = {
            start_date: data.get('start_date'),
            end_date: data.get('end_date'),
            stock_type: data.get('stock_type'),
            wheel_alert: data.get('wheel_alert'),
            alex_alert: data.get('alex_alert'),
            site_id: site_id
        };
        
        setfileteOk('Y');
        setChangeStartDate(data.get('start_date'));
        setChangeEndDate(data.get('end_date'));
        setStockType(data.get('stock_type'));
        setWheelAlert(data.get('wheel_alert'));
        setAxleAlert(data.get('alex_alert'));
        
        setLoadding(true)
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };

        axios.post(global.passUrl + 'get_report_details_by_search', postData, axiosConfig).then((res) => {
            setLoadding(false)
            if (res.status === 200) {
                if (res.data.status === 'error') {
                    setReportData([])
                    setReportDataCount(0)
                    toast.dismiss()
                    toast.error(res.data.msg, {theme: "colored",}) 
                } else {
                    setReportData([])
                    setReportData(res.data)
                    setReportDataCount(1)
                }
            } else if (res.status === 400) {
                toast.dismiss()
                toast.error("Faill!!", {theme: "colored",}) 
            }
        }).catch((err) => {
            toast.dismiss()
            toast.error(err, {theme: "colored",}) 
        })
    }

    function DownloadReport() {

        var userData = localStorage.getItem('userData');
        var result = JSON.parse(userData);

        if (get_filter === 'Y') {
            window.open(
                global.passUrl+"download_excel_report/"+get_filter+"/"+site_id+"/"+change_satrt_date+"/"+change_end_date+"/"+result.user_name+"/"+stock_type+"/"+wheel_alert+"/"+axle_alert,
                '_blank' 
            );
        }else{
            window.open(
                global.passUrl+"download_excel_report/"+get_filter+"/"+site_id+"/"+year+'-'+month+'-'+day+"/"+year+'-'+month+'-'+day+"/"+result.user_name+"/"+stock_type+"/"+wheel_alert+"/"+axle_alert,
                '_blank' 
            );
        }

    }
    
    return (
        <div className="wrapper">
            <ToastContainer />  
            <DashNavbar />
            <div className='page_layout'>
                <div><Sidebar /></div>
                <div id="content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h2>ALERT REPORT ANALYSIS</h2>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                                        <li className="breadcrumb-item active">ALERT REPORT ANALYSIS</li>
                                    </ul>
                                </div>
                            </div>
                            <hr className="colorLine" />
                            <form className="form-auth-small" id='login-form' method='POST' onSubmit={updateSite}>
                                <div className='row col-sm-12 m-0'>
                                    <div className='col-sm-2 mt-2'>
                                        <label className="custom-label">Select Satrt Date :</label>
                                        <DatePicker onChange={setStartDate} value={startDate} className="custum-date" name='start_date' maxDate={new Date()} />
                                    </div>
                                    <div className='col-sm-2 mt-2'>
                                        <label className="custom-label">Select End Date :</label>
                                        <DatePicker onChange={setEndDate} value={endDate} className="custum-date" name='end_date' minDate={startDate} />
                                    </div>
                                    <div className='col-sm-2 mt-2'>
                                        <label className="custom-label">Select Stock Type :</label>
                                        <select className='form-select' name='stock_type'>
                                            <option value={'All'}>ALL</option>
                                            <option value={'icf'}>ICF</option>
                                            <option value={'bcna'}>BCNA</option>
                                            <option value={'vande_bharat'}> Vande Bharat </option>
                                            <option value={'lhb'}> LHB </option>
                                            <option value={'boxn'}>BOXN</option>
                                            <option value={'others'}>Others</option>
                                            <option value={'loco'}>LOCO</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-2 mt-2'>
                                        <label className="custom-label">Select Wheel Alert :</label>
                                        <select className='form-select' name='wheel_alert'>
                                            <option value={'All'}>ALL</option>
                                            <option value={'ok'}>Ok</option>
                                            <option value={'alert'}>Alert</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-2 mt-2'>
                                        <label className="custom-label">Select Axle Alert :</label>
                                        <select className='form-select' name='alex_alert'>
                                            <option value={'All'}>ALL</option>
                                            <option value={'ok'}>Ok</option>
                                            <option value={'alert'}>Alert</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-2 mt-auto mb-1 text-right'>
                                        <button className="btn btn-success rounded ml-2 cursor-pointer" type='submit' ><i className="fa fa-check mr-2"></i> Submit </button>
                                    </div>
                                </div>
                            </form>
                            
                            {loading ? 
                                <div className="col-sm-12 mt-4">
                                    {report_data_count === 0 ? '' : 
                                        <div className='col-sm-12 text-right mb-4'>
                                            <button className="btn btn-dark rounded ml-2 cursor-pointer" type='button' onClick={DownloadReport} ><i className="fa fa-file-excel mr-2"></i> Download Report </button>
                                        </div>
                                    }
                                    <div className="table-bordered table-bordered">
                                        <div className='d-flex col-sm-12 p-0'>
                                            <div className='col-sm-4 '>
                                                <img src={logo1} alt="logo1" style={{ width: 60}}/>
                                            </div>
                                            <div className='col-sm-4 m-auto text-center'>ALERT REPORT ANALYSIS</div>
                                            <div className='col-sm-4 text-right'>
                                                <img src={logo} alt="logo" style={{ width: 60}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive mb-5">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date & Time</th>
                                                    <th scope="col">Log On</th>
                                                    <th scope="col">Train No</th>
                                                    <th scope="col">Speed</th>
                                                    <th scope="col">No Of Axle</th>
                                                    <th scope="col">Stock Type</th>
                                                    <th scope="col">Wheel Alerts</th>
                                                    <th scope="col">Axle Alerts</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {report_data.map( (data,index)=>
                                                    (
                                                        <tr key={index}>
                                                            <td className='text-center'>
                                                                {data.file_date_time.slice( 0,2 )+'/'+data.file_date_time.slice( 2,4 )+'/'+data.file_date_time.slice( 4,6 )} @ {data.file_date_time.slice( 6,8 )+':'+data.file_date_time.slice( 8,10 )+':'+data.file_date_time.slice( 10,12 )}
                                                            </td>
                                                            <td className='text-center' style={{ color: 'blue'}} >
                                                                <Link to={`/file_site_details/${site_id}/${data.info_id}`}>{data.file_name.slice( 0,17 )}</Link>
                                                            </td>
                                                            <td className='text-center'></td>
                                                            <td className='text-center' >{data.speed}</td>
                                                            <td className='text-center' >{data.total_alex}</td>
                                                            <td className='text-center' ></td>
                                                            <td className='text-center bg-success' >OK</td>
                                                            <td className='text-center bg-success' >OK</td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            : 
                                <div className="col-sm-12 mt-4 text-center">
                                    <img src={Loader} alt="logo" style={{ width: 30, marginTop: '5%'}}/> <br/>
                                    <span>Fetching Data...</span>
                                </div> 
                            }
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
