import React from 'react'
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import './global';
import Footer from '../nav_src/Footer';

export default function EditSite() {
    
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [site_id, setSiteId] = useState('');
    const [conatct_id, setConatctId] = useState('');
    const [email_id, setEmailId] = useState('');
    const [mobile_no, setMobileNo] = useState('');
    const [site_array, setSiteArray] = useState([]);

    const [site_id_check, setSitecheck] = useState(false);
    const [contact_check, setContactcheck] = useState(false);

    const paramsData = useParams();

    useEffect(() => {
        setDetails(paramsData.id);
        getContacts(paramsData.id);
    });

    const setDetails= (id) =>{
        if (!site_id_check) {
            var postData = {
                c_id: id,
            };
    
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            };
    
            axios.post(global.passUrl+'edit_conatct', postData, axiosConfig)
            .then((res) => {
                if (res.status === 200) {
                    setName(res.data[0].name);
                    setSiteId(res.data[0].s_id);
                    setConatctId(id);
                    setEmailId(res.data[0].email_id);
                    setMobileNo(res.data[0].mobile_no);
                    setSitecheck(true);
                } else if (res.status === 400) {
                    toast.dismiss()
                    toast.error("Faill!!", {theme: "colored",}) 
                }
            })
            .catch((err) => {
                toast.dismiss()
                toast.error(err, {theme: "colored",}) 
            })
        }
    }

    const getContacts = (id) => {
        if (!contact_check) {
            let axiosConfig = {
                headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                }
            };
            //Get all users details in bootstrap table
            axios.post(global.passUrl+'get_sites', axiosConfig)
            .then((res) => {
            setSiteArray(res.data);
            setContactcheck(true);
            });
        }
    }

    const updateSite = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        var postData = {
            name: data.get('name'),
            site_id: data.get('site_id'),
            email_id: data.get('email_id'),
            mobile_no: data.get('mobile_no'),
            c_id : data.get('c_id')
        };

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };

        axios.post(global.passUrl+'update_conatct', postData, axiosConfig)
        .then((res) => {
            if (res.status === 200) {
                toast.dismiss()
                toast.success("Updated Successfully...", {theme: "colored",}) 
                setInterval(() => {
                    navigate('/conatct')
                }, 5000);
            } else if (res.status === 400) {
                window.location.href = '';
            }
            console.log("RESPONSE RECEIVED: ", res);
        })
        .catch((err) => {
            console.log("AXIOS ERROR: ", err);
        })

    }

    const onKeyPressEvent = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!(new RegExp("[0-9]").test(keyValue)))
          event.preventDefault();
        return;
    }

    return (
        <div className="wrapper">
            <ToastContainer />
            <DashNavbar />
            <div className='page_layout'>
                <div><Sidebar /></div>
                <div id="content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h2>Edit Conatct</h2>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                                        <li className="breadcrumb-item active">Edit Conatct</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 text-right">
                                    <Link to='/conatct' >
                                        <span className="label btn-info rounded ml-2 cursor-pointer" ><i className="fa fa-step-backward"></i> Back</span>
                                    </Link>
                                </div>
                            </div>
                            <hr className='colorLine' />
                            <div className="row mt-4">
                                <div className="card col-sm-12 ml-2 p-0">
                                    <div className="card-header">
                                        Edit Conatct
                                    </div>
                                    <div className="card-body p-0">
                                        <form className="form-auth-small" id='login-form' method='POST' onSubmit={updateSite}>
                                            <input type='hidden' className="form-control" id="c_id" name="c_id" value={conatct_id} />
                                            <div className="modal-body">
                                                <div className="row col-sm-12">
                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="name" className="control-label">Site</label>  
                                                        <select className='form-control' id='site_id' name='site_id' value={site_id} onChange={(e) => setSiteId(e.target.value)}
                                                        >
                                                            <option value="">--- Select Site ---</option>
                                                            { site_array.map((result,index) => {
                                                            return ( 
                                                                <option key={index} value={result.s_id} >{result.site_name}</option>
                                                            )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="name" className="control-label">Name: </label>
                                                        <input type='text' className="form-control" id="name" name="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="row col-sm-12">
                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="name" className="control-label">Email Id: </label>
                                                        <input type='text' className="form-control" id="email_id" name="email_id" placeholder="Email Id" value={email_id} onChange={(e) => setEmailId(e.target.value)} />
                                                    </div>

                                                    <div className="form-group col-sm-6">
                                                        <label htmlFor="name" className="control-label">Mobile No: </label>
                                                        <input type='text' className="form-control" id="mobile_no" name="mobile_no" placeholder="Mobile No" value={mobile_no} onChange={(e) => setMobileNo(e.target.value)} onKeyPress={onKeyPressEvent} maxLength={10} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer text-right">
                                                <button type="submit" className="btn btn-success">Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
