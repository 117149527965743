
import React from "react";
import { useEffect } from 'react';
import '../../assets/css/App.css';
import '../../assets/css/style.css';
import logo from '../../assets/Images/kr.jpeg';

const Sidebar = () => {
    var data = localStorage.getItem('adminLoginData');
    var result = JSON.parse(data);

    useEffect(() => {
        if (result === null || data === null) {
            window.location.href = "/super-admin-login";
        }
    });

    const currentPathName = window.location.pathname
    
    return (
        <div id="sidebar">
            <div className="sidebar-header text-center">
                <img src={logo} className="App-logo" alt="logo" style={{ width: 70  , borderRadius: 18, padding: 10}}/> Super Admin
            </div>
            
            <ul className="list-unstyled components pt-1">                
                <li className={currentPathName === '/config-tool' ? "active" : ""} > 
                    <a href="/config-tool"> <i className="fa fa-home" ></i> <span className="ml-2" >Config Tool</span></a>
                </li>
            </ul>
        </div>
    )
};

export default Sidebar;
