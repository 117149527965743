import '../../assets/css/main.css';
import '../../assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/bootstrap.css';
import logo from '../../assets/Images/kr.jpeg';
import React,{ useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../pages/global';

import Loader from '../../assets/Images/default.gif';

function ResetPassword() {

  const [Loadding, setLoadding] = useState(false);
  
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    setLoadding(true);
    
    var postData = {
      email: data.get('signin_email'),
      address: window.location.origin
    };
    
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    if (postData.email) {
      axios.post(global.passUrl+'reset_password', postData, axiosConfig)
      .then((res) => {
        setLoadding(false);
        if (res.status === 200) {
          if (res.data.status === 'success' ) {
            toast.dismiss()
            toast.success(res.data.msg,{ theme: "colored", })
            setInterval(() => {
              navigate('/')
          }, 5000);
          } else {
            toast.dismiss()
            toast.error(res.data.msg,{ theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!",{ theme: "colored", })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
    } else if(data.get('signin_email') === ''){
      toast.dismiss()
      toast.error("Email'Id is Required!",{ theme: "colored", })
    }
  }

  return (
    <div className="App">
      <ToastContainer />
      <div id="wrapper" className="theme-cyan">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div className="card p-0">
                <div className="top1">
                  <img src={logo} className="App-logo" alt="logo" style={{ width: 130}}/>
                </div>
                <div className="header pl-0 pr-0">
                  <p className="card-subtitle line-on-side text-muted text-center font-medium-1"><span> Reset Password </span></p>
                </div>
                <div className="body">
                  <form className="form-auth-small" id='login-form' method='POST' onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="signin_email" className="control-label sr-only">Email</label>
                      <input type='email' className="form-control" id="signin_email" name="signin_email" placeholder="Email" />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block" >Submit {Loadding ? <img src={Loader} className="App-logo" alt="logo" style={{ width: 30, marginLeft: 15}}/> : ''} </button>
                  </form>
                </div>
                <div className='card-footer text-right' >
                  Remember your password? <a href='/'>Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer footer-static footer-dark navbar-border footerStyle">
          <p className="clearfix blue-grey1 lighten-2 text-sm-center mb-0 px-2">
            <span className="text-white float-md-center d-block d-md-inline-block">© 2025, Powered By <a href="https://www.ceryletech.com" target="_blank" rel="noreferrer" className='footer_link'>CeryleTech Innovotives LLP</a>.</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
