import React,{ useState,useEffect } from 'react'
import logout from '../../assets/Images/logout.png';
import { useNavigate } from 'react-router-dom';

export default function TopNavbar() {
    const navigate = useNavigate();    
    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    }
    var userData = localStorage.getItem('userData');
    var result = JSON.parse(userData);

    const [userName, setuserName] = useState()

    useEffect(() => {
        setuserName(result.user_name)
    }, [])

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <button type="button" id="sidebarCollapse" className="btn btn-info">
                    <i className="fas fa-align-left"></i>
                    <span></span>
                </button>
                <button className="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-align-justify"></i>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav navbar-nav custom_nav ml-auto">
                        <li className="nav-item dropdown has-arrow main-drop show">
                            <div className="cursor-pointer custom-dropdown-toggle dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true" >
                                <span className="user-img">
                                    <span className="status online"></span>
                                </span>
                                <span className="text-black">{userName}</span>
                            </div>
                            <div className="dropdown-menu custom-dropdown-menu" x-placement="bottom-start" >
                                <a className="dropdown-item" href="/change-password">Change Password</a>
                                <div className="dropdown-item cursor-pointer" onClick={handleLogout} >Logout</div>
                            </div>
                        </li>
                        <li className="nav-item active" style={{ display: 'none'}}>
                            <img className='logout_btn' src={logout} alt="logo" onClick={handleLogout} />
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
