import React from 'react'

export default function Footer() {
  return (
    <div className='custom_footer'>
        <p className="clearfix blue-grey1 lighten-2 text-sm-right mb-0 px-2">
        <span className="text-white float-md-center d-block d-md-inline-block">© 2025, Powered By <a href="https://www.ceryletech.com" target="_blank" rel="noreferrer" className='footer_link'>Ceryle innovative technology LLP</a>.</span>
        </p>
    </div>
  )
}
