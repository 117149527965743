import React from 'react'
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import './global';
import Footer from '../nav_src/Footer';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
//For API Requests
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SiteDetails() {
  const paramsData = useParams();

  const [data_check, setDatacheck] = useState(0);
  const [total_alex, setTotalAlex] = useState();
  const [speed, setSpeed] = useState();
  const [direction, setDirection] = useState();
  const [ambistion_temp, setAmbistionTemp] = useState();
  const [run_no, setRunNo] = useState();
  const [site_name, setSiteName] = useState();
  const [site_id, setSiteId] = useState();
  const [train_time, setTrainTime] = useState();
  const [train_date, setTrainDate] = useState();
  const [normal_count, setNormalCount] = useState();

  const [axle_right_high_array, setAlexRightHigh] = useState([]);
  const [axle_low_high_array, setAlexLeftHigh] = useState([]);
  const [wheel_right_high_array, setWheelRightHigh] = useState([]);
  const [wheel_low_high_array, setWheelLeftHigh] = useState([]);
  const [train_loco_data, setTrainLocoDataArray] = useState([])
  const [train_cocha_data, setTrainCochaDataArray] = useState([])
  
  const [loco_count, setLocoCount] = useState('0')
  const [cocha_count, setCochaCount] = useState('0')

  const [wheel_today_count, setWheelTodayCount] = useState();
  const [wheel_month_count, setWheelMonthCount] = useState();

  const [axle_today_count, setAxleTodayCount] = useState();
  const [axle_month_count, setAxleMonthCount] = useState();

  const [wheel_alert_data, setWheelAlertData] = useState([]);
  const [axle_alert_data, setAxleAlertData] = useState([]);
  
  const [axle_today_count_as_file, setAxleTodayCountAsFile] = useState();
  const [wheel_today_count_as_file, setWheelMonthCountAsFile] = useState();
  const [wheel_axle_month_count_as_file, setAxleWheelMonthCountAsFile] = useState();

  useEffect(() => {
    setDetails(paramsData.site_id);

    setInterval(() => {      
      checkNewArrivedTrain(paramsData.site_id)
    }, 10000);
  });

  function checkNewArrivedTrain(id) {
    var postData = {
      site_id: id,
    };
    
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
      }
    };

    axios.post(global.passUrl+'get_arrived_train', postData, axiosConfig).then((res) => {
      if (res.status === 200) {        
        console.log(res.data.value)
        if (res.data.value == 1) {
          console.log(res.data.value)
          var updatePostData = {
            update_value: 0,
            site_id: id
          }

          let axiosConfig = {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
            }
          };
      
          axios.post(global.passUrl+'update_arrived_train_data', updatePostData, axiosConfig).then((res) => {
            if (res.status === 200) {
              if (res.data.status === 'error') {
                toast.dismiss()
                toast.error(res.data.msg, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              } else {
                newTrainArrivedDetails(paramsData.site_id);
              }
            } else if (res.status === 400) {
              toast.dismiss()
              toast.error("Fail!!", {theme: "colored",}) 
            }
          }).catch((err) => {
            toast.dismiss()
            toast.error(err, {theme: "colored",})
          })
        }
      } else if (res.status === 400) {
        toast.dismiss()
        toast.error("Fail!!", {theme: "colored",}) 
      }
    }).catch((err) => {
      toast.dismiss()
      toast.error(err, {theme: "colored",})
    })
  }

  const setDetails = (id) => {
    setSiteId(id);
    if (data_check !== 1) {
      var postData = {
        site_id: id,
      };
      
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      axios.post(global.passUrl+'get_site_details', postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'error') {
            toast.dismiss()
            toast.error(res.data.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            const direction = res.data.direction
            const fileDateTime = res.data.site_data.file_date_time
            const directionArray = direction.split("@!");
            
            setDatacheck(1)
            setDirection(directionArray[0])
            setAmbistionTemp(directionArray[1])
            setSpeed(res.data.speed)
            setTotalAlex(res.data.total_alex)
            setRunNo('Log_M'+res.data.site_data.file_date_time)
            setSiteName(res.data.site_data.site_name)
            setTrainDate(fileDateTime.slice( 0,2 )+'/'+fileDateTime.slice( 2,4 )+'/'+fileDateTime.slice( 4,6 ))
            setTrainTime(fileDateTime.slice( 6,8 )+':'+fileDateTime.slice( 8,10 )+':'+fileDateTime.slice( 10,12 ))
            setNormalCount(res.data.total_alex * 2)            
            setTrainLocoDataArray(res.data.loco_details_data);
            setTrainCochaDataArray(res.data.cocha_details__data);

            setLocoCount(res.data.locoCount);
            setCochaCount(Math.round((res.data.total_alex - (res.data.locoCount * 6))/4));
             
            setWheelTodayCount(res.data.today_wheel_alert);
            setWheelMonthCount(res.data.monthly_wheel_alert);
            setAxleTodayCount(res.data.today_axle_alert);
            setAxleMonthCount(res.data.monthly_axle_alert);

            setWheelAlertData(res.data.today_wheel_alert_data);
            setAxleAlertData(res.data.today_axle_alert_data);

            
            setAxleTodayCountAsFile(res.data.today_wheel_alert_data.length);
            setWheelMonthCountAsFile(res.data.today_axle_alert_data.length);
            setAxleWheelMonthCountAsFile((res.data.today_wheel_alert_data.length) + (res.data.today_axle_alert_data.length));

            
            const alexHight = [];
            const alexLow = [];
            const wheelHight = [];
            const wheelLow = [];
              
            res.data.train_data.forEach((file) => {
              alexHight.push(file.axle_right_high)
              alexLow.push(file.axle_left_high)
              wheelHight.push(file.wheel_right_high)
              wheelLow.push(file.wheel_left_high)
            })
            setAlexRightHigh(alexHight)
            setAlexLeftHigh(alexLow)
            setWheelRightHigh(wheelHight)
            setWheelLeftHigh(wheelLow)

          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", {theme: "colored",}) 
        }
      }).catch((err) => {
        toast.dismiss()
        toast.error(err, {theme: "colored",})
      })
    }
  }

  const newTrainArrivedDetails = (id) => {
    setSiteId(id);
    if (data_check !== 1) {
      var postData = {
        site_id: id,
      };
      
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      axios.post(global.passUrl+'get_site_details', postData, axiosConfig).then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'error') {
            toast.dismiss()
            toast.error(res.data.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            
            toast.dismiss()
            toast.success('New Train Arrived...', {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            const direction = res.data.direction
            const fileDateTime = res.data.site_data.file_date_time
            const directionArray = direction.split("@!");
            
            setDatacheck(1)
            setDirection(directionArray[0])
            setAmbistionTemp(directionArray[1])
            setSpeed(res.data.speed)
            setTotalAlex(res.data.total_alex)
            setRunNo('Log_M'+res.data.site_data.file_date_time)
            setSiteName(res.data.site_data.site_name)
            setTrainDate(fileDateTime.slice( 0,2 )+'/'+fileDateTime.slice( 2,4 )+'/'+fileDateTime.slice( 4,6 ))
            setTrainTime(fileDateTime.slice( 6,8 )+':'+fileDateTime.slice( 8,10 )+':'+fileDateTime.slice( 10,12 ))
            setNormalCount(res.data.total_alex * 2)            
            setTrainLocoDataArray(res.data.loco_details_data);
            setTrainCochaDataArray(res.data.cocha_details__data);

            setLocoCount(res.data.locoCount);
            setCochaCount(Math.round((res.data.total_alex - (res.data.locoCount * 6))/4));
             
            setWheelTodayCount(res.data.today_wheel_alert);
            setWheelMonthCount(res.data.monthly_wheel_alert);
            setAxleTodayCount(res.data.today_axle_alert);
            setAxleMonthCount(res.data.monthly_axle_alert);

            setWheelAlertData(res.data.today_wheel_alert_data);
            setAxleAlertData(res.data.today_axle_alert_data);

            
            setAxleTodayCountAsFile(res.data.today_wheel_alert_data.length);
            setWheelMonthCountAsFile(res.data.today_axle_alert_data.length);
            setAxleWheelMonthCountAsFile((res.data.today_wheel_alert_data.length) + (res.data.today_axle_alert_data.length));

            
            const alexHight = [];
            const alexLow = [];
            const wheelHight = [];
            const wheelLow = [];
              
            res.data.train_data.forEach((file) => {
              alexHight.push(file.axle_right_high)
              alexLow.push(file.axle_left_high)
              wheelHight.push(file.wheel_right_high)
              wheelLow.push(file.wheel_left_high)
            })
            setAlexRightHigh(alexHight)
            setAlexLeftHigh(alexLow)
            setWheelRightHigh(wheelHight)
            setWheelLeftHigh(wheelLow)

          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!", {theme: "colored",}) 
        }
      }).catch((err) => {
        toast.dismiss()
        toast.error(err, {theme: "colored",})
      })
    }
  }

  return (
    <div className="wrapper">
      <ToastContainer />
      <DashNavbar />
      <div className='page_layout'>
        <div><Sidebar /></div>
        <div id="content">
          <div className="container-fluid">
            <div className="block-header m-0">
              <div className="row">
                <div className="col-sm-6">
                  <h2>Detailed Data</h2>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                    <li className="breadcrumb-item active">Site Details</li>
                  </ul>
                </div>
                <div className="col-sm-6 text-right m-auto">
                  <Link to={`/view-report/${site_id}`}>
                    <span className="btn btn-dark rounded ml-2 cursor-pointer" ><i className="fa fa-history mr-2"></i> View History</span>
                  </Link>
                </div>
              </div>
              <hr className="colorLine" />
              <div className="row" style={{ paddingLeft: 15, paddingRight: 15 }}>
                <div className='card p-0'>
                  <div className="card-header custom-card-header text-center p-2"><h5>Summarised Information</h5></div>
                  <div className="card-body custom-card-body pl-3 pr-3 pt-2 pb-0">
                    <div className="row">
                      <div className="col-sm-4">Location: {site_name} </div>
                      <div className="col-sm-4">Date: {train_date} </div>
                      <div className="col-sm-4">Train Time: {train_time} </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-4">Run No: {run_no}</div>
                      <div className="col-sm-4">Train Direction: {direction}</div>
                      <div className="col-sm-4">Ambient Temperature: {ambistion_temp}</div>
                    </div>
                  </div>
                  <div className="card-body custom-card-body pl-3 pr-3 pt-2 pb-2">
                    <u className='train_info_style'>TRAIN INFORMATION</u>
                    <div className="row mt-2">
                      <div className="col-sm-4">Total Axles: {total_alex}</div>
                      <div className="col-sm-4">Average Train Speed: {speed} Kmph
                      </div>
                      <div className="col-sm-4">No of Alarms: {wheel_axle_month_count_as_file}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-4">Engine: {loco_count}</div>
                      <div className="col-sm-4">Coach/Wagon: {cocha_count}</div>
                      <div className="col-sm-4">Train Type: COACHING</div>
                    </div>
                  </div>
                </div>

                <div className='card p-0 col-6'>
                  <div className="card-header custom-card-header text-center p-2"><h5>Axle Box - Alarm Information</h5></div>
                  <div className="card-body custom-card-body">
                    <div className="row">
                      <div className="col-sm-6 text-center">NORMAL: {normal_count}</div>
                      <div className="col-sm-6 text-center">ALERT: {axle_today_count_as_file}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6 text-center alert-table-style">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <td colSpan="4">&nbsp;</td>
                            </tr>
                          </thead>
                          <thead className='custom-table-head' >
                            <tr>
                              <th scope="col">VEHICLE NO</th>
                              <th scope="col">WHEEL NO</th>
                              <th scope="col">SIDE</th>
                              <th scope="col">C</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(() => {
                              var posts = [];
                              if (axle_alert_data.length) {
                                for (let index = 0; index < axle_alert_data.length; index++) {
                                  posts.push(
                                    <tr>
                                      <td></td>
                                      <td>{axle_alert_data[index].wheel_no}</td>
                                      <td>{axle_alert_data[index].side}</td>
                                      <td>{axle_alert_data[index].c_val}</td>
                                    </tr>
                                  )
                                }
                              } else {
                                posts.push(
                                  <tr>
                                    <td colSpan={4}>No Data</td>
                                  </tr>
                                )
                              }
                              return posts;
                            })()}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-sm-6 text-center">
                        <table className="table table-bordered">
                          <thead >
                            <tr>
                              <td colSpan="4">Axle Box Alarm Counter</td>
                            </tr>
                          </thead>
                          <thead className='custom-table-head' >
                            <tr>
                              <th scope="col">Alarm</th>
                              <th scope="col">Today</th>
                              <th scope="col">This Month</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='bg-danger text-white'>
                              <td>Counter</td>
                              <td>{axle_today_count}</td>
                              <td>{axle_month_count}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card p-0 col-6'>
                  <div className="card-header custom-card-header text-center p-2"><h5>Wheel - Alarm Information</h5></div>
                  <div className="card-body custom-card-body">
                    <div className="row">
                      <div className="col-sm-6 text-center">NORMAL: {normal_count}</div>
                      <div className="col-sm-6 text-center">ALERT: {wheel_today_count_as_file}</div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-sm-6 text-center alert-table-style">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <td colSpan="4">&nbsp;</td>
                            </tr>
                          </thead>
                          <thead className='custom-table-head'>
                            <tr>
                              <th scope="col">VEHICLE NO</th>
                              <th scope="col">WHEEL NO</th>
                              <th scope="col">SIDE</th>
                              <th scope="col">C</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(() => {
                              var posts = [];
                              if (wheel_alert_data.length) {
                                for (let index = 0; index < wheel_alert_data.length; index++) {
                                  posts.push(
                                    <tr>
                                      <td></td>
                                      <td>{wheel_alert_data[index].wheel_no}</td>
                                      <td>{wheel_alert_data[index].side}</td>
                                      <td>{wheel_alert_data[index].c_val}</td>
                                    </tr>
                                  )
                                }
                              } else {
                                posts.push(
                                  <tr>
                                    <td colSpan={4}>No Data</td>
                                  </tr>
                                )
                              }
                              return posts;
                            })()}
                          </tbody>
                        </table>
                      </div>
                      <div className="col-sm-6 text-center">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <td colSpan="4">Wheel Alarm Counter</td>
                            </tr>
                          </thead>
                          <thead className='custom-table-head'>
                            <tr>
                              <th scope="col">Alarm</th>
                              <th scope="col">Today</th>
                              <th scope="col">This Month</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='bg-danger text-white' >
                              <td>Counter</td>
                              <td>{wheel_today_count}</td>
                              <td>{wheel_month_count}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card p-0'>
                  <div className="row">
                    <div className="col-sm-8 text-center">
                      <div className="table-custom-scrollbar">
                        <table className="table table-bordered table-striped mb-0">
                          <thead className='custom-table-head' >
                            <tr>
                              <td>Rolling Stock Position</td>
                              <td>Axle No</td>
                              <td>Left Axle Box Temperature (°C)</td>
                              <td>Right Axle Box Temperature (°C)</td>
                              <td>Left Wheel Temperature (°C)</td>
                              <td>Right Wheel Temperature (°C)</td>
                            </tr>
                          </thead>
                          <tbody>
                            {(() => {
                              var posts = [];
                              var postCount = 1;
                              var locoCount = 1;
                              for (let index = 0; index < train_loco_data.length; index++) {
                                if (postCount === 1) {
                                  posts.push( 
                                    <tr data-index={index}>
                                      <td rowSpan={6}>{'LOCO'+locoCount}</td> 
                                      <td>{postCount}</td> 
                                      <td>{train_loco_data[index].axle_right_high}</td> 
                                      <td>{train_loco_data[index].axle_left_high}</td> 
                                      <td>{train_loco_data[index].wheel_right_high}</td> 
                                      <td>{train_loco_data[index].wheel_left_high}</td> 
                                    </tr> 
                                  )
                                } else {
                                  posts.push( 
                                    <tr data-index={index}>
                                      <td>{postCount}</td> 
                                      <td>{train_loco_data[index].axle_right_high}</td> 
                                      <td>{train_loco_data[index].axle_left_high}</td> 
                                      <td>{train_loco_data[index].wheel_right_high}</td> 
                                      <td>{train_loco_data[index].wheel_left_high}</td> 
                                    </tr> 
                                  )
                                }  
                                if (postCount === 6) {
                                  postCount= 1;
                                  locoCount = locoCount + 1;
                                } else {
                                  postCount= postCount + 1; 
                                }
                              }
                              
                              for (let j = 0; j < ((6 * loco_count) - train_loco_data.length); j++) {
                                posts.push(
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                )
                              }

                              return posts;
                            })()}

                            {(() => {
                              var posts = [];
                              var cochaCount = 1;
                              var cochaStrCount = 1;
                              for (let index = 0; index < train_cocha_data.length; index++) {
                                if (cochaCount === 1) {
                                  posts.push( 
                                    <tr data-index={index}>
                                      <td rowSpan={4}>{'Coach '+cochaStrCount}</td> 
                                      <td>{cochaCount}</td> 
                                      <td>{train_cocha_data[index].axle_right_high}</td> 
                                      <td>{train_cocha_data[index].axle_left_high}</td> 
                                      <td>{train_cocha_data[index].wheel_right_high}</td> 
                                      <td>{train_cocha_data[index].wheel_left_high}</td> 
                                    </tr> 
                                  )
                                } else {
                                  posts.push( 
                                    <tr data-index={index}>
                                      <td>{cochaCount}</td> 
                                      <td>{train_cocha_data[index].axle_right_high}</td> 
                                      <td>{train_cocha_data[index].axle_left_high}</td> 
                                      <td>{train_cocha_data[index].wheel_right_high}</td> 
                                      <td>{train_cocha_data[index].wheel_left_high}</td> 
                                    </tr> 
                                  )
                                }  
                                if (cochaCount === 4) {
                                  cochaCount= 1;
                                  cochaStrCount = cochaStrCount + 1;
                                } else {
                                  cochaCount= cochaCount + 1; 
                                }
                              }
                              return posts;
                            })()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-4 text-center">
                      <br/>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: "line",
                            height: (9 / 16 * 100) + '%'
                          },
                          title: {
                            text: 'Wheel Temperature'
                          },
                          yAxis: {
                            title: {
                              text: 'Wheel Temp'
                            }
                          },
                          series: [
                            {
                              name: 'Wheel 1',
                              data: wheel_right_high_array
                            },
                            {
                              name: 'Wheel 2',
                              data: wheel_low_high_array
                            }
                          ]
                        }}
                      />
                      <br/>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: "line",
                            height: (9 / 16 * 100) + '%'
                          },
                          title: {
                            text: 'Axle Box Temperature'
                          },
                          yAxis: {
                            title: {
                              text: 'Alex Temp'
                            }
                          },
                          series: [
                            {
                              name: 'Alex 1',
                              data: axle_right_high_array
                            },
                            {
                              name: 'Alex 2',
                              data: axle_low_high_array
                      
                            }
                          ]
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
