import React from 'react'
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";
import '../../assets/css/App.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './global';
import Footer from '../nav_src/Footer';

export default function Contacts() {

  const [site_id, setSiteId] = useState();
  const [site_name, setSiteName] = useState();
  const [site_id_check, setSitecheck] = useState(false);
  const [contact_check, setContactcheck] = useState(false);

  const paramsData = useParams();

  useEffect(() => {
    getContactDetails(paramsData.id);
    getSiteDetails(paramsData.id);
  });

  const getContactDetails = (id) => {
    if (!site_id_check) {
      var postData = {
        id: id,
      };

      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      axios.post(global.passUrl + 'getContactDetails', postData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          setFormVal(res.data)
          setSitecheck(true);
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!",{ theme: "colored", })
        }
      })
      .catch((err) => {
        toast.dismiss()
        toast.error(err,{ theme: "colored", })
      })
    }
  }

  const getSiteDetails = (id) => {
    if (!contact_check) {
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };
      var postData = {
        s_id: id,
      };
      //Get all users details in bootstrap table
      axios.post(global.passUrl + 'get_sites_name', postData, axiosConfig)
      .then((res) => {
        if (res.data.status === 'error') {
          toast.dismiss()
          toast.error(res.data.message,{ theme: "colored", })
          
        } else {
          setSiteId(res.data.site_id);
          setSiteName(res.data.site_name);
          setContactcheck(true); 
        }
      });
    }
  }

  const [formVal, setFormVal] = useState([{name:'', email_id:'', mobile_no:'',}])
  const addRow = () => {
    setFormVal([...formVal, {name:'', email_id:'', mobile_no:''}])
  }
  const onRemove=(i) => {
    const newForm = [...formVal]
    newForm.splice(i, 1)
    setFormVal(newForm)
  }
  const onHandle = (e, i) => {
    let newForm = [...formVal]
    newForm[i][e.target.name]= e.target.value
    setFormVal(newForm)
  }
  const formValidation=(formVal)=>{
    const data = [...formVal]
    var re = /\S+@\S+\.\S+/;
    let valid = true
    for (let index = 0; index < data.length; index++) {
      if(data[index].name === "") {
        data[index].nameCheck = "Name is required"
        valid = false
      }else{
        data[index].nameCheck = ""
        valid = true
      }

      if(data[index].email_id === "") {
        data[index].emailCheck = "Email Id is required."
        data[index].emailFormat = ""
        valid = false
        
      } else if(!re.test(data[index].email_id)) {
          data[index].emailFormat = "Invalid Email"
          data[index].emailCheck = ""
          valid = false
      } else{
        data[index].emailCheck = ""
        data[index].emailFormat = ""
        valid = true

      }

      if(data[index].mobile_no === "") {
        data[index].conatctCheck = "Mobile no is required."
        valid = false
      } else if(data[index].mobile_no.length < 10) {
        data[index].conatctFormat = "Mobile number should be 10 digits long."
        data[index].conatctCheck = ""
        valid = false
      } else{
        data[index].conatctCheck = ""
        data[index].conatctFormat = ""
        valid = true
      }      
    }
    setFormVal(data)
    return valid
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const errorRes = formValidation(formVal)
    if(errorRes) {
      var postData = {
        siteId: site_id,
        Data: formVal
      };
      
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          "Access-Control-Allow-Origin": "*",
        }
      };

      axios.post(global.passUrl + 'updateContacts', postData, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.status === 'success') {
            toast.dismiss()
            toast.success(res.data.message,{ theme: "colored", })
            setInterval(() => {
              window.location.href = '/site';
            }, 5000);
          }else{
            toast.dismiss()
            toast.error(res.data.message,{ theme: "colored", })
          }
        } else if (res.status === 400) {
          toast.dismiss()
          toast.error("Fail!!",{ theme: "colored", })
        }
      })
      .catch((err) => {
        toast.dismiss()
        toast.error("Fail!!",{ theme: "colored", })
      })
    } else{
    }
  }

  return (
    <div className="wrapper">
      <ToastContainer />
      <DashNavbar />
      <div className='page_layout'>
        <div><Sidebar /></div>
        <div id="content">
          <div className="container-fluid">
            <div className="block-header">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h2>Update contacts</h2>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                    <li className="breadcrumb-item active">Update contacts</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-right">
                  <Link to='/site' >
                    <span className="label btn-info rounded ml-2 cursor-pointer" ><i className="fa fa-step-backward"></i> Back</span>
                  </Link>
                </div>
              </div>
              <hr className='colorLine' />
              <div className="row mt-4 ml-0 mr-0">
                <div className='card col-sm-12'>
                  <div className='card-body pl-0 pr-0'>
                    <div style={{ paddingBottom: 10, borderBottom: '1px dashed'}}>
                      <label style={{ color: '#0000008a',fontSize:14,margin:0 }}>Location Name</label>
                      <div style={{ color: '#0000008a' }} >{site_name}</div>
                    </div>
                    <div className='d-flex' style={{ paddingTop: 25,paddingBottom: 10, borderBottom: '2px solid #00A5BA'}}>
                      <div className="col-lg-6 col-md-6 col-sm-12 pl-0">
                        <h2>Contacts</h2>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 text-right pr-1">
                        <span className="label btn-dark rounded ml-2 cursor-pointer" onClick={addRow} ><i className="fa fa-plus"></i></span>
                      </div>
                    </div>

                    <div className='mt-4'>
                      <form onSubmit={onSubmit}>
                        {formVal.map((item, i)=> (
                          <div key={i}  >
                            <div className="row col-sm-12 m-0 p-0">
                              <div className="form-group col-sm-4">
                                  <label htmlFor="name" className="control-label">Name: </label>
                                  <input type='text' className="form-control" id="name" name="name" placeholder="Name" value={item.name || ""} onChange={(e)=> onHandle(e, i)}/>
                                  <div className='error_msg' style={{color:'red'}}>{item.nameCheck}<br/>{item.nameLengthCheck}</div>
                              </div>
                              <div className="form-group col-sm-4">
                                  <label htmlFor="name" className="control-label">Email Id: </label>
                                  <input type='text' className="form-control" id="email_id" name="email_id" placeholder="Email Id" value={item.email_id || ""} onChange={(e)=> onHandle(e, i)}/>
                                  <div className='error_msg' style={{color:'red'}}>{item.emailCheck}<br/>{item.emailFormat}</div>
                              </div>
                              <div className="form-group col-sm-3">
                                  <label htmlFor="name" className="control-label">Mobile No: </label>
                                  <input type='text' className="form-control" id="mobile_no" name="mobile_no" placeholder="Mobile No" maxLength={10} value={item.mobile_no || ""} onChange={(e)=> onHandle(e, i)}/>
                                  <div className='error_msg' style={{color:'red'}}>{item.conatctCheck}<br/>{item.conatctFormat}</div>
                              </div>
                              <div className="form-group col-sm-1 m-auto text-right">
                                <span className="label btn-danger rounded ml-2 cursor-pointer"  onClick={()=>onRemove(i)} ><i className="fa fa-minus"></i></span>
                              </div>
                            </div>                          
                          </div>              
                        ))}
                        <div style={{marginTop:'20px'}} className="text-right">
                          <button type="submit" className="btn btn-success">Update</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}
