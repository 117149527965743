import React,{useState} from 'react'
import DashNavbar from "../nav_src/Sidebar";
import Sidebar from "../nav_src/TopNavbar";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import EyeIcon from '../../assets/Images/eye.png';
import hiddenEye from '../../assets/Images/hidden.png';
import '../../assets/css/App.css';
import './global';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Footer from '../nav_src/Footer';

export default function ChangePassword() {
    const navigate = useNavigate();
    const [isPasswordShow, setIsPasswordShow] = useState(false);
    const [newIsPasswordShow, setNewIsPasswordShow] = useState(false);
    const [confirmIsPasswordShow, setConfirmIsPasswordShow] = useState(false);

    const toggleIsPasswordShowValue = () => {
        setIsPasswordShow(!isPasswordShow);
    };

    const toggleNewIsPasswordShowValue = () => {
        setNewIsPasswordShow(!newIsPasswordShow);
    };

    const toggleConfirmIsPasswordShowValue = () => {
        setConfirmIsPasswordShow(!confirmIsPasswordShow);
    };

    const updatePassword = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        var userData = localStorage.getItem('userData');
        var result = JSON.parse(userData);
        var postData = {
            id : result.id,
            current_password: data.get('current_password'),
            new_password: data.get('new_password'),
            confirm_password: data.get('confirm_password')
        };

        if(postData.new_password === postData.confirm_password){
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            };
            const url = global.passUrl+'update_password';
            axios.post(url, postData, axiosConfig)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status === 'success' ) {
                        toast.dismiss()
                        toast.success(res.data.msg,{ theme: "colored", })
                        setInterval(() => {
                            navigate('/dashboard')
                        }, 5000);
                    } else {
                        toast.dismiss()
                        toast.error(res.data.msg,{ theme: "colored", })
                        setInterval(() => {
                            navigate('/change-password')
                        }, 5000);
                    }
                } else if (res.status === 400) {
                    toast.dismiss()
                    toast.error("Faill!!",{ theme: "colored", })
                    setInterval(() => {
                        navigate('/change-password')
                    }, 5000);
                }
                console.log("RESPONSE RECEIVED: ", res.data.status +' '+res.data.msg);
            })
            .catch((err) => {
                toast.dismiss()
                toast.error(err,{ theme: "colored", })
                console.log("AXIOS ERROR: ", err);
            })
        }else{
            toast.dismiss()
            toast.error("New Password & Confirm Password is does not match.",{ theme: "colored", })
        }
    }
    return (
        <div className="wrapper">
            <ToastContainer />
            <DashNavbar />
            <div className='page_layout'>
                <div id="content">
                    <div><Sidebar /></div>
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-sm-12">
                                    <h2>Change Password</h2>
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/dashboard"><i className="fa fa-home"></i></a></li>
                                        <li className="breadcrumb-item active">Change Password</li>
                                    </ul>
                                </div>
                            </div>
                            <hr className='colorLine' />
                            <div className="row mt-4 col-sm-12 m-0 p-0">
                                <div className="card p-0">
                                    <div className="card-header">
                                        Change Password
                                    </div>
                                    <div className="card-body">
                                        <form className="form-auth-small" id='login-form' method='POST' onSubmit={updatePassword}>
                                            <div className='row'>
                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="name" className="control-label">Current Password: </label>
                                                    <input type={ isPasswordShow ? 'text' : 'password'} className="form-control" id="current_password" name="current_password" placeholder="Current Password" required/>
                                                    <div className='eye-icon' onClick={toggleIsPasswordShowValue}>
                                                        { isPasswordShow ? <img src={EyeIcon} alt="logo"/> : <img src={hiddenEye} alt="logo"/>}
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="name" className="control-label">New Password: </label>
                                                    <input type={ newIsPasswordShow ? 'text' : 'password'} className="form-control" id="new_password" name="new_password" placeholder="New Password" required/>
                                                    <div className='eye-icon' onClick={toggleNewIsPasswordShowValue}>
                                                        { newIsPasswordShow ? <img src={EyeIcon} alt="logo"/> : <img src={hiddenEye} alt="logo"/>}
                                                    </div>
                                                </div>
                                                <div className="form-group col-sm-4">
                                                    <label htmlFor="name" className="control-label">Confirm Password: </label>
                                                    <input type={ confirmIsPasswordShow ? 'text' : 'password'} className="form-control" id="confirm_password" name="confirm_password" placeholder="Confirm Password" required/>
                                                    <div className='eye-icon' onClick={toggleConfirmIsPasswordShowValue}>
                                                        { confirmIsPasswordShow ? <img src={EyeIcon} alt="logo"/> : <img src={hiddenEye} alt="logo"/>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 text-right'>
                                                    <button type="submit" className="btn btn-success col-1">Update</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
